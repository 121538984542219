/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance } from 'axios';
import { useLocation } from '@reach/router';
import { isConstructorDeclaration } from 'typescript';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

export interface ObjectVo {
  [key: string]: any;
}

// customer definition
// empty

export class SampleCampaignControllerService {
  /**
   * 캠페인 > 2021 푸른 하늘의 날 캠페인 > 실팔찌 재고 파악
   */
  static getBraceletStockUsingGet1(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/202109/campaign/aaa/bracelet';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 캠페인 > 2021 푸른 하늘의 날 캠페인
   */
  static getProductStockUsingGet1(
    params: {
      /** 사이즈(사이즈1 : size1, 사이즈2 : size2) */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/202109/campaign/aaa/stock';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UnicasterSurveyControllerService {
  /**
   * 유니캐스터 캠페인 > 참여자 입력
   */
  static insertUnicasterSurveyUsingPost(
    params: {
      /** 문제1 */
      answer01: string;
      /** 문제2 */
      answer02: string;
      /** 문제3 */
      answer03: string;
      /** 문제4 */
      answer04: string;
      /** 문제5 */
      answer05: string;
      /** 문제6 */
      answer06: string;
      /** 문제7 */
      answer07: string;
      /** 문제8 */
      answer08: string;
      /** 문제9 */
      answer09: string;
      /** 문제10 */
      answer10: string;
      /** 이메일 */
      email: string;
      /** 연락처 */
      mobile: string;
      /** 이름 */
      name: string;
      /** 작성자 IP, 값 넣지 않아도 됩니다 */
      regip?: string;
      /** 해설캐릭터 */
      result: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/202111/campaign/unicaster/insert';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        answer01: params['answer01'],
        answer02: params['answer02'],
        answer03: params['answer03'],
        answer04: params['answer04'],
        answer05: params['answer05'],
        answer06: params['answer06'],
        answer07: params['answer07'],
        answer08: params['answer08'],
        answer09: params['answer09'],
        answer10: params['answer10'],
        email: params['email'],
        mobile: params['mobile'],
        name: params['name'],
        regip: params['regip'],
        result: params['result']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SaveLifeControllerService {
  /**
   * 함께하는 방법  > 생명을 구하는 선물 > IG 메인 배너
   */
  static getIgMainBannerUsingGet(options: IRequestOptions = {}): Promise<IGBannerVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/IG/campaign/banner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법  > 생명을 구하는 선물 > 카테고리 목록
   */
  static getCategoryCodeListUsingGet(options: IRequestOptions = {}): Promise<GiftVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/IG/save-life/category';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법  > 생명을 구하는 선물 > 상품 목록
   */
  static getSaveLifeListUsingGet(options: IRequestOptions = {}): Promise<GiftVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/IG/save-life/gift';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법  > 생명을 구하는 선물 > 장바구니 담기
   */
  static addGiftCartUsingPost(
    params: {
      /** 장바구니 물품 수량 */
      count: string;
      /** 상품코드번호 */
      productCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/IG/save-life/gift/cart';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { count: params['count'], productCode: params['productCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법  > 생명을 구하는 선물 > 장바구니 상품 삭제하기
   */
  static deleteGiftCartUsingPost(
    params: {
      /** 전체/일부 삭제 모드(전체 일 경우에만 'ALL') */
      deleteMode?: string;
      /** 상품 코드 목록 */
      productCodeList: string[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/IG/save-life/gift/cart/delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { deleteMode: params['deleteMode'], productCodeList: params['productCodeList'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법  > 생명을 구하는 선물 > 장바구니 목록 가져오기
   */
  static getListGiftCartUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/IG/save-life/gift/cart/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법  > 생명을 구하는 선물 > 장바구니 상품 수량 수정하기
   */
  static modifyCountGiftCartUsingPost(
    params: {
      /** 장바구니 물품 수량 */
      count: string;
      /** 상품코드번호 */
      productCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/IG/save-life/gift/cart/modify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { count: params['count'], productCode: params['productCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActiveControllerService {
  /**
   * 하는일 > 어린이 스토리 조회 / loveMySelf 스토리 / 한국 정부 협력 활동스토리 조회
   */
  static getStoryListUsingGet(
    params: {
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActiveVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/active/child-story';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { boardIndexNumber: params['boardIndexNumber'], category: params['category'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 하는일 > 해당 주제의 최근 소식 8건 조회
   */
  static getNewsListUsingGet(
    params: {
      /** 관련 주제 카테고리 */
      category: string;
      /** 상태코드 */
      statusCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActiveVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/active/news';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { category: params['category'], statusCode: params['statusCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ActiveNewsControllerService {
  /**
   * 소식 목록 전체조회 (Build용)
   */
  static getAllNewsListUsingGet(
    params: {
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 배너표시 여부 */
      newsMainYn?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 보드 상태코드 */
      statusCode?: string;
      /** 상단표시 여부 */
      topYn?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/active/news/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        newsMainYn: params['newsMainYn'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        statusCode: params['statusCode'],
        topYn: params['topYn']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 소식 상세 > 첨부파일 조회
   */
  static getNewsFileListUsingGet(
    params: {
      /** 게시판 종류(BOARD_CATEGORY_CODE) */
      boardCategoryCode?: string[];
      /** 글 번호(BOARD_INDEX_NUMBER) */
      boardIndexNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/active/news/file';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { boardCategoryCode: params['boardCategoryCode'], boardIndexNumber: params['boardIndexNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 소식 조회 페이징 및 검색조건
   */
  static getNewsListPageByCategoryUsingGet(
    params: {
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 검색키워드(제목+내용) */
      keyword?: string;
      /** 배너표시 여부 */
      newsMainYn?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 보드 상태코드 */
      statusCode?: string;
      /** 상단표시 여부 */
      topYn?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/active/news/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        keyword: params['keyword'],
        newsMainYn: params['newsMainYn'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        statusCode: params['statusCode'],
        topYn: params['topYn']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 소식 상세 > 관련 글 전체 조회
   */
  static getRelAllNewsListUsingGet(
    params: {
      /** 글 번호(BOARD_INDEX_NUMBER) */
      boardIndexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/active/news/rel';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { boardIndexNumber: params['boardIndexNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 소식 상세 > 관련 글 페이징 조회
   */
  static getRelNewsListUsingGet(
    params: {
      /** 글 번호(BOARD_INDEX_NUMBER) */
      boardIndexNumber: string;
      /** 검색키워드(제목+내용) */
      keyword?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/active/news/rel/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardIndexNumber: params['boardIndexNumber'],
        keyword: params['keyword'],
        page: params['page'],
        pagePerCount: params['pagePerCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BlueSky2021ControllerService {
  /**
   * 캠페인 > 2021 푸른 하늘의 날 캠페인 > 실팔찌 재고 파악
   */
  static getBraceletStockUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2021/blue-sky/bracelet';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 캠페인 > 2021 푸른 하늘의 날 캠페인
   */
  static getProductStockUsingGet(
    params: {
      /** 사이즈(사이즈1 : size1, 사이즈2 : size2) */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2021/blue-sky/stock';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CardDrawingControllerService {
  /**
   * 캠페인 > 카드 그림 그리기 공모전  작품 심사 목록
   */
  static getListUsingGet1(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2021/card-drawing/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FieldTripControllerService {
  /**
   * 캠페인 > 온라인 필드트립 신청
   */
  static insertUsingPost(
    params: {
      /** 동의여부 */
      agreeYn: string;
      /** 후원자명 */
      applyUserName: string;
      /** 후원자연락처 */
      applyUserPhone: string;
      /** 내용 */
      contents: string;
      /** 후원자ID */
      donorId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2021/fieldtrip/insert';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        agreeYn: params['agreeYn'],
        applyUserName: params['applyUserName'],
        applyUserPhone: params['applyUserPhone'],
        contents: params['contents'],
        donorId: params['donorId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CalendarDrawingControllerService {
  /**
   * 캠페인 > 달력 그림 그리기 공모전  작품 상세내역
   */
  static getCalWorkUsingGet(
    params: {
      /** 인덱스 번호 */
      indexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BoCalendarDrawingVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2022/cal-drawing/detail{indexNumber}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { indexNumber: params['indexNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 캠페인 > 달력 그림 그리기 공모전  작품 심사 목록
   */
  static getCalListUsingGet(
    params: {
      /** 참가구분 */
      ageRequirement?: string;
      /** 날짜종류 */
      dateRequirement?: string;
      /** 종료일시 */
      endDate?: string;
      /** 검색어 */
      keyword?: string;
      /** 검색종류 */
      keywordRequirement?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 심사구분 */
      stage?: string;
      /** 시작일시 */
      startDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2022/cal-drawing/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ageRequirement: params['ageRequirement'],
        dateRequirement: params['dateRequirement'],
        endDate: params['endDate'],
        keyword: params['keyword'],
        keywordRequirement: params['keywordRequirement'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        stage: params['stage'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 캠페인 > 캘린더 그림 그리기 공모전  로그인 확인
   */
  static getListUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2022/cal-drawing/loginYn';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 캠페인 > 캘린더 그림 그리기 공모전  접수
   */
  static saveEntryUsingPost(
    params: {
      /** 참가분야 */
      age: string;
      /** 생일 */
      birthday: string;
      /** 첨부파일 */
      file: any[];
      /** 성별 */
      gender: string;
      /** 등록번호 */
      indexNumber?: number;
      /** 이름 */
      name: string;
      /** 접수번호 */
      registerIndex?: number;
      /** 접수번호 */
      registerNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/2022/cal-drawing/saveEntry';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        age: params['age'],
        birthday: params['birthday'],
        gender: params['gender'],
        indexNumber: params['indexNumber'],
        name: params['name'],
        registerIndex: params['registerIndex'],
        registerNumber: params['registerNumber']
      };
      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SupportLegacyCampaignControllerService {
  /**
   * 캠페인 > 인생기부 3일노트 이벤트
   */
  static apply3DaysEventUsingPost(
    params: {
      /** 수령지 주소 */
      address1: string;
      /** 수령지 상세주소, 값 넣지 않아도 됩니다 */
      address2?: string;
      /** 연락처 */
      contact: string;
      /** 접속 단말기 타입(web,mobile) */
      deviceType: string;
      /** 이메일, 값 넣지 않아도 됩니다 */
      email?: string;
      /** 개인정보 수집 및 이용 동의(Y,N) */
      isAgreeYn: string;
      /** 이름 */
      name: string;
      /** 타입 */
      type: string;
      /** 작성자 IP, 값 넣지 않아도 됩니다 */
      userIp?: string;
      /** 우편번호 */
      zipcode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/campaign/3days/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        address1: params['address1'],
        address2: params['address2'],
        contact: params['contact'],
        deviceType: params['deviceType'],
        email: params['email'],
        isAgreeYn: params['isAgreeYn'],
        name: params['name'],
        type: params['type'],
        userIp: params['userIp'],
        zipcode: params['zipcode']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ChildParticipationControllerService {
  /**
   * 함께하는 방법 > 어린이 참여 > 어린이 지구촌 체험관 : 신청서 전송
   */
  static childApplyInsertUsingPost(
    params: {
      /** 기관명 */
      agency: string;
      /** 신청서 첨부파일 */
      applyAttGrpNo?: string;
      /** 신청자 이메일주소 */
      applyUserEmail: string;
      /** 신청자명 */
      applyUserName: string;
      /** 신청자 휴대폰번호('-'제외) */
      applyUserPhone: string;
      /** 기타요청사항 */
      contents?: string;
      /** 참가신청 확인 동의여부(Y/N) */
      isAgreeYn1: string;
      /** 아동권리교육 동의여부(Y/N 선택 안한 경우엔 'N') */
      isAgreeYn2: string;
      /** 신청구분 */
      organType: string;
      /** 인원수 */
      personnelCount: string;
      /** 신청선택날짜(ex:2021-05-28) */
      selectDate: string;
      /** 신청 선택 시간대(10/14) */
      selectTime: string;
      /** 기관 기본주소 */
      agencyAddrBasic: string;
      /** 기관 상세주소 */
      agencyAddrDetail?: string;
      /** 기관 우편번호 */
      agencyAddrZip: string;
      /** 기관연락처 */
      agencyTel: string;
      /** 동행성인 보호자 인원수 */
      accompanyCnt: string;
      /** 시작 시간 (hhmm, ex: 1300) */
      startTime: string;
      /** 종료 시간 (hhmm, ex: 1350) */
      endTime: string;
      /** 학년 */
      organGrade?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/child/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        agency: params['agency'],
        applyAttGrpNo: params['applyAttGrpNo'],
        applyUserEmail: params['applyUserEmail'],
        applyUserName: params['applyUserName'],
        applyUserPhone: params['applyUserPhone'],
        contents: params['contents'],
        isAgreeYn1: params['isAgreeYn1'],
        isAgreeYn2: params['isAgreeYn2'],
        organType: params['organType'],
        personnelCount: params['personnelCount'],
        selectDate: params['selectDate'],
        selectTime: params['selectTime'],
        agencyAddrBasic: params['agencyAddrBasic'],
        agencyAddrDetail: params['agencyAddrDetail'],
        agencyAddrZip: params['agencyAddrZip'],
        agencyTel: params['agencyTel'],
        accompanyCnt: params['accompanyCnt'],
        startTime: params['startTime'],
        endTime: params['endTime'],
        organGrade: params['organGrade']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  
  /**
   * 함께하는 방법 > 어린이 참여 > 어린이 지구촌 체험관  : 사용자 체험관 진행 예정인 신청 목록 조회
   */
  static getChildApplyMyListUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/child/apply/my/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * 함께하는 방법 > 어린이 참여 > 어린이 지구촌 체험관  : 사용자 체험관 신청 현황 조회
   */
    static getChildApplyMyUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
      return new Promise((resolve, reject) => {
        let url = basePath + '/unicef/api/fo/child/apply/my';
  
        const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
  
        let data = null;
  
        configs.data = data;
        axios(configs, resolve, reject);
      });
    }

  /**
   * 함께하는 방법 > 어린이 참여 > 어린이 지구촌 체험관  : 신청 내역 정보 조회
   */
  static getChildApplyInfoUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/child/apply/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > 어린이 참여 > 어린이 지구촌 체험관 : 신청 취소 요청
   */
  static applyCancelModifyUsingPost(
    params: {
      /** 인덱스번호 */
      indexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChildApplyVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/child/cancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { indexNumber: params['indexNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > 어린이 참여 > 어린이 지구촌 체험관 : 신청 캘린더(해당 월 조회) -> 날짜별 분리 목록
   */
  static getApplyScheduleListUsingGet(
    params: {
      /** 검색종료날짜(ex:2021-06-01) */
      endDate: string;
      /** 검색시작날짜(ex:2021-06-01) */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChildScheduleVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/child/schedule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { endDate: params['endDate'], startDate: params['startDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > 어린이 참여 > 어린이 지구촌 체험관 : 신청 캘린더(해당 월 조회)-> 전체 목록
   */
  static getApplyScheduleAllListUsingGet(
    params: {
      /** 검색종료날짜(ex:2021-06-01) */
      endDate: string;
      /** 검색시작날짜(ex:2021-06-01) */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/child/schedule/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { endDate: params['endDate'], startDate: params['startDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SubscribeNewsControllerService {
  /**
   * 유니세프 소식 사용자 등록
   */
  static postSubscribeNewsUserUsingPost(
    params: {
      /** 진입 형태 (필드삭제 예정입니다.) */
      appType?: string;
      /** 이메일 */
      email: string;
      /** 사용자 이메일 (필드삭제 예정입니다.) */
      enewsEmail?: string;
      /** 사용자명 (필드삭제 예정입니다.) */
      enewsName?: string;
      /** 진입경로 , 값 넣지 않아도 됩니다 */
      entryPath?: string;
      /** 진입타입 , 값 넣지 않아도 됩니다 */
      entryType?: string;
      /** 사용자IP (필드삭제 예정입니다.) */
      ip?: string;
      /** isSend (필드삭제 예정입니다.) */
      isSend?: string;
      /** 이름 */
      name: string;
      /** 사용자IP , 값 넣지 않아도 됩니다 */
      registerIp?: string;
      /**  */
      trackCode?: string;
      /** 작성일 (필드삭제 예정입니다.) */
      writeDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/subscribe/news/user';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        appType: params['appType'],
        email: params['email'],
        enewsEmail: params['enewsEmail'],
        enewsName: params['enewsName'],
        entryPath: params['entryPath'],
        entryType: params['entryType'],
        ip: params['ip'],
        isSend: params['isSend'],
        name: params['name'],
        registerIp: params['registerIp'],
        trackCode: params['trackCode'],
        writeDate: params['writeDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CorporateControllerService {
  /**
   * 단체후원  >  기업/기관 주요소식 안내 롤링배너(관리자 설계 필요 : 임시데이터)
   */
  static getBannerListUsingGet(options: IRequestOptions = {}): Promise<BbsBoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/csr/banner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 단체후원 > 기업단체 로고 대카테고리 목록 조회 API
   */
  static getCsrCategoryListUsingGet(options: IRequestOptions = {}): Promise<CsrVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/csr/category';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 단체후원 > 기업단체 로고 목록
   */
  static getCsrLogoListUsingGet(options: IRequestOptions = {}): Promise<CsrVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/csr/logo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 단체후원 > 문의하기
   */
  static postQnaInsertUsingPost(
    params: {
      /** 소재지(시/도) */
      address1: string;
      /** 소재지(시/군/구) */
      address2: string;
      /** 첨부파일번호 */
      attGrpNo?: string;
      /** 기업/단체명 */
      companyName: string;
      /** 연락처('-'제외) */
      contact: string;
      /** 문의내용 */
      contents: string;
      /** 부서명 */
      department?: string;
      /** 이메일주소 */
      email: string;
      /** 기업기관후원안내 동의여부 (Y/N) */
      isAgreeYn: string;
      /** 담당자명 */
      managerName: string;
      /** 구분(기업 or 재단/단체) */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/csr/qna';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        address1: params['address1'],
        address2: params['address2'],
        attGrpNo: params['attGrpNo'],
        companyName: params['companyName'],
        contact: params['contact'],
        contents: params['contents'],
        department: params['department'],
        email: params['email'],
        isAgreeYn: params['isAgreeYn'],
        managerName: params['managerName'],
        type: params['type']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class reliefPlaceControllerService {
  /**
   * 메인 > 구호 현장 목록 가져오기
   */
  static getReliefPlaceListUsingGet(options: IRequestOptions = {}): Promise<reliefPlaceVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/siteDesign/reliefPlace/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PrivacyControllerService {
  /**
   * 영상처리기기 관련방침 조회
   */
  static getPrivacyCctvPolicyListUsingGet(
    params: {
      /** 버전 */
      version?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivacyVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/etc/privacy/cctv-list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { version: params['version'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 위탁업체 조회
   */
  static getPrivacyCorpListUsingGet(
    params: {
      /** 버전 */
      version?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivacyVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/etc/privacy/corp-list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { version: params['version'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 개인정보 처리방침  조회
   */
  static getPrivacyListUsingGet(
    params: {
      /** 버전 */
      version?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrivacyVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/etc/privacy/privacy-list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { version: params['version'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CampaignEventControllerService {
  /**
   * 단체후원  >  이벤트/캠페인 목록
   */
  static getEventListUsingGet(
    params: {
      /** 검색키워드(제목+내용) */
      keyword?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 분류값(전체 : A /진행중 : B / 종료 : C) */
      status: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/event/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keyword: params['keyword'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        status: params['status']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FaqControllerService {
  /**
   * FAQ 상위 카테고리 목록 조회
   */
  static faQcategoryListUsingGet(options: IRequestOptions = {}): Promise<FaqCodeVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/faq/category';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * FAQ 하위 카테고리 목록 조회
   */
  static faqChildcategoryListUsingGet(options: IRequestOptions = {}): Promise<FaqCodeVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/faq/child-category';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FoGnbControllerService {
  /**
   * FO GNB 목록 가져오기
   */
  static getFoGnbListUsingGet(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/gnb';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HonorsClubControllerService {
  /**
   * 함께하는 방법  > (고액후원/아너스클럽 & 유산기부 공통) : 문의하기
   */
  static insertHonorsQnaUsingPost(
    params: {
      /**  */
      mCellphone?: string;
      /**  */
      mEmail?: string;
      /**  */
      mKname?: string;
      /**  */
      mRegtype?: string;
      /** 이메일(email@email.com) */
      email: string;
      /** 동의여부(Y/N) */
      isAgreeYn: string;
      /** 연락처(01011112222 '-'제외) */
      mobile: string;
      /** 이름 */
      name: string;
      /** honors(아너스)/legacy(유산기부) */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/honors/qna';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        MCellphone: params['mCellphone'],
        MEmail: params['mEmail'],
        MKname: params['mKname'],
        MRegtype: params['mRegtype'],
        email: params['email'],
        isAgreeYn: params['isAgreeYn'],
        mobile: params['mobile'],
        name: params['name'],
        type: params['type']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HospitalControllerService {
  /**
   * 병원후원 > 함께하는 병원 목록
   */
  static getHospitalListUsingGet(options: IRequestOptions = {}): Promise<HospitalVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/hospital/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IntroControllerService {
  /**
   * 소개페이지 컨텐츠 조회
   */
  static getContactListUsingGet(options: IRequestOptions = {}): Promise<ContentVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/content';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrgControllerService {
  /**
   * 연락처 조회
   */
  static getContactListUsingGet1(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/org/contact';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이사회 조회
   */
  static getCouncilUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/org/council';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 총회/이사회 회의록 조회
   */
  static getMeetinNoteUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/org/meeting';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 조직도 조회
   */
  static getOrganizationUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/org/organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PeopleControllerService {
  /**
   * 유니세프와 사람들 조회
   */
  static getPeopleAllUsingGet(options: IRequestOptions = {}): Promise<PeopleVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/people/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HistoryControllerService {
  /**
   * 걸어온길 기간별 조회
   */
  static getPeriodListUsingGet(options: IRequestOptions = {}): Promise<PeriodVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/period';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 걸어온길 연도별 조회
   */
  static getYearListUsingGet(options: IRequestOptions = {}): Promise<YearVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/intro/year';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DonorsChorusControllerService {
  /**
   * 후원자 합창단 목록 조회
   */
  static getChorusListUsingGet(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/chorus/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원자 합창단 상세조회
   */
  static getChorusDetailUsingGet(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/chorus/{index}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DonorsEventControllerService {
  /**
   * 후원자 초청행사 목록 조회
   */
  static getEventListUsingGet1(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/event/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원자 초청행사 상세조회
   */
  static getEventDetailUsingGet(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/event/{index}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DonorsStoryControllerService {
  /**
   * 후원자 이야기 목록 조회
   */
  static getStroyListUsingGet(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/story/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원자 이야기 상세조회
   */
  static getStroyDetailUsingGet(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/story/{index}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DonorsTripControllerService {
  /**
   * 후원자 현장방문 목록 조회
   */
  static getTripListUsingGet(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/trip/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원자 현장방문 이야기 상세조회
   */
  static getTripDetailUsingGet(
    params: {
      /**  */
      cPage?: number;
      /**  */
      tCnt?: string;
      /**  */
      tPage?: string;
      /** 게시물 인덱스 */
      bbIdx?: string;
      /** 게시판 인덱스 */
      bcIdx?: string;
      /** 비고 */
      desc?: string;
      /** 보여질 갯수 */
      gapPerPage: string;
      /** 게시글 번호 */
      index?: string;
      /** 조회수 증가 */
      isAddReadCnt?: string;
      /** 파일 갯수 리턴 0 :안받음,1 :받음 */
      isGetAttachCnt?: string;
      /** 댓글수 리턴 0:안받음, 1: 받음 */
      isGetCommentCnt?: string;
      /** 이전 다음 게시물 가져오기 */
      isGetPrevNext?: string;
      /** 진행상태 - 0 : 전체, 1 : 진행중, 2 : 지난 게시물 */
      isGetProgres?: string;
      /** 0 : 일반, 1 : 관리자 */
      isManager?: string;
      /** QUERY CONSOLE PRINT문 실행 - 0 : 미실행, 1 : 실행 */
      isQueryPrint?: string;
      /** 정렬조건 */
      orderby: string;
      /** 검색조건절 */
      strSearch: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BbsBoardVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/involve/donors/trip/{index}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CPage: params['cPage'],
        TCnt: params['tCnt'],
        TPage: params['tPage'],
        bbIdx: params['bbIdx'],
        bcIdx: params['bcIdx'],
        desc: params['desc'],
        gapPerPage: params['gapPerPage'],
        index: params['index'],
        isAddReadCnt: params['isAddReadCnt'],
        isGetAttachCnt: params['isGetAttachCnt'],
        isGetCommentCnt: params['isGetCommentCnt'],
        isGetPrevNext: params['isGetPrevNext'],
        isGetProgres: params['isGetProgres'],
        isManager: params['isManager'],
        isQueryPrint: params['isQueryPrint'],
        orderby: params['orderby'],
        strSearch: params['strSearch']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LegacyControllerService {
  /**
   * 함께하는 방법 > 유산기부 :  안내자료 신청 팝업
   */
  static insertLegacyFileUsingGet(
    params: {
      /**  */
      mCellphone?: string;
      /**  */
      mEmail?: string;
      /**  */
      mKname?: string;
      /**  */
      mRegtype?: string;
      /** 이메일(email@email.com) */
      email: string;
      /** 동의여부(Y/N) */
      isAgreeYn: string;
      /** 연락처(01011112222 '-'제외) */
      mobile: string;
      /** 이름 */
      name: string;
      /** honors(아너스)/legacy(유산기부) */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/legacy/file';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        MCellphone: params['mCellphone'],
        MEmail: params['mEmail'],
        MKname: params['mKname'],
        MRegtype: params['mRegtype'],
        email: params['email'],
        isAgreeYn: params['isAgreeYn'],
        mobile: params['mobile'],
        name: params['name'],
        type: params['type']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FoMainControllerService {
  /**
   * 메인_유니세프와 사람들
   */
  static getUnicefPeopleListUsingGet(options: IRequestOptions = {}): Promise<PeopleVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/main/people';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MySupportHistoryControllerService {
  /**
   * 마이페이지 > 후원내역 > 완료후원 api
   */
  static getMyCompleteHistoryUsingGet(
    params: {
      /** 날짜종류 */
      dateRequirement?: string;
      /** 검색 기간 종료일 */
      endDate?: string;
      /** 후원 구분코드(전체일 경우엔 빈값) */
      glCode?: string;
      /** 검색어 */
      keyword?: string;
      /** 검색종류 */
      keywordRequirement?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 검색 기간 */
      period?: string;
      /** 검색 기간 시작일 */
      startDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/history/complete';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        dateRequirement: params['dateRequirement'],
        endDate: params['endDate'],
        glCode: params['glCode'],
        keyword: params['keyword'],
        keywordRequirement: params['keywordRequirement'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        period: params['period'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 후원내역 > 완료후원 후원 상품코드 목록 api
   */
  static getMyCompleteCodeListUsingGet(options: IRequestOptions = {}): Promise<myHistoryCodeVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/history/complete/code';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 후원내역 > 내가 놓친 후원
   */
  static getMyMissSupportHistoryUsingGet(options: IRequestOptions = {}): Promise<myMissHistoryVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/history/miss';
      //let token = localStorage.getItem(`or.kr.unicef.path.token`) as any;
      let token = sessionStorage.getItem(`or.kr.unicef.path.token`) as any;
      let donorId = sessionStorage.getItem(`or.kr.unicef.miss.oauth.token`) as any;
      if(!token){token = '0';}
      if(!donorId){donorId = '0';}
      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;
      configs.data = data;
      if(token == "2"){
        const encKey = JSON.parse(sessionStorage.getItem("__donateCrypto") as any);
        configs.params = {
          pathType:token,
          donorId:donorId,
          encCert:  encKey?.cert,
          publicKey: encKey?.key,
          expiry: encKey?.expiry,
        };
      } else {
        configs.params = {
          pathType:token,
          donorId:donorId,
        };
      }
      axios(configs, resolve, reject);
    });
  }
}

export class MyPageControllerService {
  /**
   * 마이페이지 메인 > 정기후원 요약
   */
  static getMyDonorInfoUsingGet(options: IRequestOptions = {}): Promise<myPageMainRegularVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/main/active';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 메인 > 나의 알림 목록 조회
   */
  static getMyAlarmListUsingGet(options: IRequestOptions = {}): Promise<myAlarmVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/main/alarm';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 메인 > 대시보드 컨텐츠 영역(함께해요! 모든 어린이가 함께하는 세상)
   */
  static getMyPageDashBoardContentsUsingGet(options: IRequestOptions = {}): Promise<myPageBoardContentsVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/main/dashboard-contents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 메인 > 나의 해당년도 정기 월별후원 내역
   */
  static getMyDonorHistoryUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/main/history';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 메인 > 1:1 문의내역 카운트 조회 API
   */
  static getMyQnaCountUsingGet(options: IRequestOptions = {}): Promise<myPageQnaVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/main/qna';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 메인 > 증액 카드 조회 API
   */
  static getIncreaseCardUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/personalization/donate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;
      configs.data = data;
      axios(configs, resolve, reject);
    })
  }
}

export class MyPersonalInfoControllerService {
  /**
   * 마이페이지 > 개인정보 조회
   */
  static getMyPersonalInfoUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/personal';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 웹회원 탈퇴
   */
  static setDropMyinfoByUserIdUsingPost(
    params: {
      /** E소식지 수신여부(Y,N) */
      dropEnewsYn: string;
      /** 유니세프에 바라는 의견 (Long Text) */
      dropMemo?: string;
      /** 탈퇴사유(라디오 value값) */
      dropReason: string;
      /** 삭제 구분 (사용자 탈퇴의 경우 'M', 관리자 페이지에서 삭제시 'A', 값 넣지 않아도 됩니다 */
      dropType?: string;
      /** 이름, 값 넣지 않아도 됩니다 */
      name?: string;
      /** 클라이언트 IP, 값 넣지 않아도 됩니다 */
      registerIp?: string;
      /** 사용자ID, 값 넣지 않아도 됩니다 */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/personal/drop';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        dropEnewsYn: params['dropEnewsYn'],
        dropMemo: params['dropMemo'],
        dropReason: params['dropReason'],
        dropType: params['dropType'],
        name: params['name'],
        registerIp: params['registerIp'],
        userId: params['userId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 개인정보 수정
   */
  static setModifyMyPersonalInfoByUserIdUsingPost(
    params: {
      /** 주소 (후원자의 경우 입력) */
      address?: string;
      /** 주소상세 (후원자의 경우 입력) */
      addressDetail?: string;
      /** 후원자번호, 값 넣지 않아도 됩니다 */
      donorId?: string;
      /** 후원자여부, 값 넣지 않아도 됩니다 */
      donorYn?: string;
      /** 이메일 */
      email?: string;
      /** 우편 동의 여부 ('Y','N') ( 후원자 [donorId > 0]의 경우 필수로 보내주세요 ), TO-BE 신규필드 */
      mailDm?: string;
      /** 이메일 동의 여부 ('Y','N'), TO-BE 신규필드 */
      mailEdm?: string;
      /** SMS 수신동의('Y','N'), TO-BE 신규필드 */
      mailSms?: string;
      /** 핸드폰번호 */
      mobilePhoneNumber?: string;
      /** 수정자 IP, 값 넣지 않아도 됩니다 */
      modifyIp?: string;
      /** 성명/단체명 */
      name?: string;
      /** 패스워드 */
      password?: string;
      /** 패스워드New, 해당 값으로 패스워드가 변경 됩니다. */
      passwordNew?: string;
      /** 후원정보 보관 동의 여부 ('Y','N') ( 후원자 [donorId > 0]의 경우 필수로 보내주세요 ), TO-BE 신규필드 */
      privacyAgree?: string;
      /** 소셜타입('G','F','N', 소셜회원의 경우에만 넣어주세요. */
      socialType?: string;
      /** 사용자ID, 값 넣지 않아도 됩니다 */
      userId?: string;
      /** 우편번호 (후원자의 경우 입력) */
      zipCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/personal/modify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.data = {
        address: params['address'],
        addressDetail: params['addressDetail'],
        donorId: params['donorId'],
        donorYn: params['donorYn'],
        email: params['email'],
        mailDm: params['mailDm'],
        mailEdm: params['mailEdm'],
        mailSms: params['mailSms'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        modifyIp: params['modifyIp'],
        name: params['name'],
        password: params['password'],
        passwordNew: params['passwordNew'],
        privacyAgree: params['privacyAgree'],
        socialType: params['socialType'],
        userId: params['userId'],
        zipCode: params['zipCode']
      };
      
      axios(configs, resolve, reject);
    });
  }
  /**
   * 휴대폰 SMS 인증번호 발송
   */
  static getSmsAuthKeyUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/personal/smsAuth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MyQnaControllerService {
  /**
   * 마이페이지 > 1:1문의 문의글 등록
   */
  static myQnaInsertUsingPost(
    params: {
      /** 공통코드번호 */
      boardCategoryCode: string;
      /** 내용 */
      contents: string;
      /** 문의유형카테고리 */
      optData4: string;
      /** 제목 */
      subject: string;
      /** 사용자이름(불가피할 경우 말씀해주세요!) */
      writerName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/qna/insert';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        contents: params['contents'],
        optData4: params['optData4'],
        subject: params['subject'],
        writerName: params['writerName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 1:1문의 내역 목록
   */
  static getMyQnaListUsingGet(
    params: {
      /** 검색키워드(제목+내용) */
      keyword?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/qna/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'], page: params['page'], pagePerCount: params['pagePerCount'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 1:1문의 알림 조회
   */
  static getMyQnaNoticeUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/qna/notice';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MyReceiptControllerService {
  /**
   * 마이페이지 > 기부금 영수증 - 신청하기
   */
  static myReciptApplyUsingPost(
    params: {
      /** 주민번호 표시(1)/미표시(0) */
      dispJuminno: string;
      /**  */
      receiptType?: string;
      /** 집주소1 */
      receiverAddress?: string;
      /** 집주소2(상세) */
      receiverAddress2?: string;
      /** 신청 팩스번호 */
      receiverFax?: string;
      /** 이메일주소 */
      receiverMail?: string;
      /** 후원자명 */
      receiverName: string;
      /** 우편번호 */
      receiverZipcode?: string;
      /** 요청형태(프린트,이메일,팩스)- E:이메일 ,F:팩스 , P:우편 */
      requestType: string;
      /** 신청년도 */
      selectYear: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/receipt/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        dispJuminno: params['dispJuminno'],
        receiptType: params['receiptType'],
        receiverAddress: params['receiverAddress'],
        receiverAddress2: params['receiverAddress2'],
        receiverFax: params['receiverFax'],
        receiverMail: params['receiverMail'],
        receiverName: params['receiverName'],
        receiverZipcode: params['receiverZipcode'],
        requestType: params['requestType'],
        selectYear: params['selectYear']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MyRewardControllerService {
  /**
   * 마이페이지 > 발송현황 : 주소 변경
   */
  static postMyAddressEditUsingPost(
    params: {
      /** 주소 */
      address: string;
      /** 상세주소 */
      addressDetail?: string;
      /** 인증접근키 */
      authKey: string;
      /** 후원자번호 */
      donorId: string;
      /** 후원자주소저장여부 */
      dpoAddressYn: string;
      /** 우편번호 */
      zipCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/reward/address-edit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        addressDetail: params['addressDetail'],
        authKey: params['authKey'],
        donorId: params['donorId'],
        dpoAddressYn: params['dpoAddressYn'],
        zipCode: params['zipCode']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원리워드 상품 발송정보 조회
   */
  static getDonationAddressInfoUsingGet(
    params: {
      /** 인증접근키 */
      authKey: string;
      /** 후원자번호 */
      donorId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/reward/address-info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { authKey: params['authKey'], donorId: params['donorId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 발송현황
   */
  static getMyRewardListUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/reward/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MySupportInfoControllerService {
  /**
   * 마이페이지 > 후원 및 결제 정보 : 결제 금액 변경
   */
  static modifyAmountByGiftIdUsingPost(
    params: {
      /** 변경 후원금액 */
      amount: string;
      /** 새해다짐챌린지 진입여부 */
      challengeYn?: string;
      /** giftId */
      giftId: string;
      /** 마이페이지>후원정보>금액변경 시,'Y'값 보내주세요 / 대시보느는 'N' */
      incDecYn: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/support-info/amount/modify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        amount: params['amount'],
        challengeYn: params['challengeYn'],
        giftId: params['giftId'],
        incDecYn: params['incDecYn']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 후원 및 결제 정보 : 현재 진행중인 정기후원 목록
   */
  static getMySupportInfoListUsingGet(options: IRequestOptions = {}): Promise<myActiveHistoryVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/support-info/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 후원 및 결제 정보 : 결제정보 조회
   */
  static getMyPayInfoUsingGet(options: IRequestOptions = {}): Promise<RegularPayInfoVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/support-info/pay-info';
      //let token = localStorage.getItem(`or.kr.unicef.path.token`) as any;
      let token = sessionStorage.getItem(`or.kr.unicef.path.token`) as any;
      let donorId = sessionStorage.getItem(`or.kr.unicef.miss.oauth.token`) as any;
      //let userId = sessionStorage.getItem(`or.kr.unicef.miss.oauth.token_info`) as any;

    
      
      if(!token){token = '0';}
      if(!donorId){donorId = '0';}
      //if(!userId){userId = '0';}

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      let data = null;
      configs.data = data;
      if(token == "2"){
        const encKey = JSON.parse(sessionStorage.getItem("__donateCrypto") as any);
        configs.params = {
          pathType:token,
          donorId:donorId,
          encCert:  encKey?.cert,
          publicKey: encKey?.key,
          expiry: encKey?.expiry,
        };
      } else {
        configs.params = {
          pathType:token,
          donorId:donorId,
        };
      }
     
      axios(configs, resolve, reject);
    });
  }
  /**
   * 마이페이지 > 후원 및 결제 정보 : 결제정보 변경
   */
  static modifyAccountProcessUsingPost(
    params: {
      /**  */
      nPayKey?: string;
      /** (자동이체)예금주 휴대폰번호 */
      accountContact?: string;
      /** (자동이체필수)예금주명 */
      accountName?: string;
      /** (자동이체필수)계좌번호('-'제외) */
      accountNo?: string;
      /** (자동이체_본인일 경우)ACS 타입(ARS / sign: 전자서명) */
      acsType?: string;
      /** (자동이체필수)은행코드 */
      bankCode?: string;
      /** 생년월일(yyyyMMdd) */
      birthday?: string;
      /** 사업자번호('-'제외) */
      businessNumber?: string;
      /** (카드필수)카드코드번호 */
      cardNo?: string;
      /** 후원자ID(donorId) */
      donorId: string;
      /** giftId */
      giftId?: string;
      /** 결제일 */
      payDay: string;
      /** 결제수단 */
      payTypeCd: string;
      /**  */
      pledgeId?: string;
      /** (자동이체_본인이 아닐 경우)예금주와의 관계 */
      relation?: string;
      /** (자동이체필수)서명값 바이너리 */
      signBinary?: string;
      /** (카드필수)카드유효기간 (yyMM) */
       validDate?: string;
       /** 변경경로 */
       pathType?: string;
         /** 모듈keyword */
       cpCode?: string;
         /** donorTypeCode */
       donorTypeCode?: string;
        /** solicitCode */
        solicitCode?: string;
  
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/support-info/pay/modify';
      //const token = localStorage.getItem(`or.kr.unicef.path.token`) as any;
      const token = sessionStorage.getItem(`or.kr.unicef.path.token`) as any;
      const solicitCode = sessionStorage.getItem(`or.kr.unicef.miss.solicitCode`) as any;
      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      configs.params = {
        NPayKey: params['nPayKey'],
        accountContact: params['accountContact'],
        accountName: params['accountName'],
        accountNo: params['accountNo'],
        acsType: params['acsType'],
        bankCode: params['bankCode'],
        birthday: params['birthday'],
        businessNumber: params['businessNumber'],
        cardNo: params['cardNo'],
        donorId: params['donorId'],
        giftId: params['giftId'],
        payDay: params['payDay'],
        payTypeCd: params['payTypeCd'],
        pledgeId: params['pledgeId'],
        relation: params['relation'],
        signBinary: params['signBinary'],
        validDate: params['validDate'],
        pathType: token,
        solicitCode: solicitCode,
      };
      let data = null;



      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PartyForUnicefControllerService {
  /**
   * 함께하는 방법 > party for UNICEF : 파티 후기 작성하기
   */
  static partyEndWriteUsingPost(
    params: {
      /** 주소 */
      address: string;
      /** 상세주소 */
      addressDetail?: string;
      /** [기부금 영수증]_후원 금액 */
      amount?: string[];
      /** 가장 어려웠던 점 */
      answerDifficult: string;
      /** 기타 의견 */
      answerEtc?: string;
      /** 가장 즐거웠던 점 */
      answerFavorite: string;
      /** 파티 진행된 순서 */
      answerOrder: string;
      /** 알게된 경로 */
      answerRoute: string;
      /** 성공적인 비결 */
      answerSecret: string;
      /** 홍보방법제안 */
      answerSuggestion?: string;
      /** 생년월일(2021-01-01) */
      birthday: string;
      /** 연락처('-'제외) */
      contact: string;
      /** 이메일주소 */
      email: string;
      /**  */
      endInexNumber?: string[];
      /** 참여 인원수 */
      fundParty: string;
      /** 총 모금액 */
      fundRaised: string;
      /** sns동의여부(Y/N) */
      isAgreeYn1: string;
      /** 소식동의여부(Y/N) */
      isAgreeYn2: string;
      /** [기부금 영수증] _신청자 주민등록번호 */
      juminNumber?: string[];
      /** 성명/단체명 */
      name: string;
      /** 주최자성명(단체명으로 신청 시) */
      nameRep?: string;
      /** 첨부파일 멀티 파일번호 */
      photo1?: string;
      /** [기부금 영수증]_신청자 이메일 */
      receiptEmail?: string[];
      /** [기부금 영수증] _신청자 이름 */
      receiptName?: string[];
      /** 기부금영수증 신청 인원수 */
      receiptParticipant?: string;
      /** 우편번호('-'제외) */
      zipcode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/party/after';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        addressDetail: params['addressDetail'],
        amount: params['amount'],
        answerDifficult: params['answerDifficult'],
        answerEtc: params['answerEtc'],
        answerFavorite: params['answerFavorite'],
        answerOrder: params['answerOrder'],
        answerRoute: params['answerRoute'],
        answerSecret: params['answerSecret'],
        answerSuggestion: params['answerSuggestion'],
        birthday: params['birthday'],
        contact: params['contact'],
        email: params['email'],
        endInexNumber: params['endInexNumber'],
        fundParty: params['fundParty'],
        fundRaised: params['fundRaised'],
        isAgreeYn1: params['isAgreeYn1'],
        isAgreeYn2: params['isAgreeYn2'],
        juminNumber: params['juminNumber'],
        name: params['name'],
        nameRep: params['nameRep'],
        photo1: params['photo1'],
        receiptEmail: params['receiptEmail'],
        receiptName: params['receiptName'],
        receiptParticipant: params['receiptParticipant'],
        zipcode: params['zipcode']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > party for UNICEF : 파티 신청하기
   */
  static applyPartyUsingPost(
    params: {
      /** 주소 */
      address: string;
      /** 상세주소 */
      addressDetail?: string;
      /** 파티를 알릴 예정 */
      answerExpected: string;
      /** 후원하고 싶은 사업 */
      answerField: string;
      /** party for UNICEF 소개할 계획 */
      answerPlan: string;
      /** 모금활동을 하고 싶은 이유 */
      answerReason: string;
      /** 유니세프를 알게 된 경로 */
      answerRoute: string;
      /** 생년월일(2021-01-01) */
      birthday: string;
      /** 연락처('-'제외) */
      contact: string;
      /** 이메일주소 */
      email: string;
      /** 투명성동의여부(Y/N) */
      isAgreeYn1: string;
      /** 유의사항동의여부(Y/N) */
      isAgreeYn2: string;
      /** 성명/단체명 */
      name: string;
      /** 주최자성명 */
      nameRep?: string;
      /** 예상 모금액 */
      partyAmount: string;
      /** 파티날짜(2021-01-01) */
      partyDate: string;
      /** 모금파티/활동이름 */
      partyName: string;
      /** 예상참가인원 */
      partyParticipant: string;
      /** 파티장소 */
      partyPlace: string;
      /** 파티관련SNS */
      partySns?: string;
      /** 파티 세부 예상 일정 */
      partyTimetable: string;
      /** 모금파티/활동종류 */
      partyType: string;
      /** 우편번호('-'제외) */
      zipcode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/party/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
    // configs.params = {
    //   address: params['address'],
    //   addressDetail: params['addressDetail'],
    //   answerExpected: params['answerExpected'],
    //   answerField: params['answerField'],
    //   answerPlan: params['answerPlan'],
    //   answerReason: params['answerReason'],
    //   answerRoute: params['answerRoute'],
    //   birthday: params['birthday'],
    //   contact: params['contact'],
    //   email: params['email'],
    //   isAgreeYn1: params['isAgreeYn1'],
    //   isAgreeYn2: params['isAgreeYn2'],
    //   name: params['name'],
    //   nameRep: params['nameRep'],
    //   partyAmount: params['partyAmount'],
    //   partyDate: params['partyDate'],
    //   partyName: params['partyName'],
    //   partyParticipant: params['partyParticipant'],
    //   partyPlace: params['partyPlace'],
    //   partySns: params['partySns'],
    //   partyTimetable: params['partyTimetable'],
    //   partyType: params['partyType'],
    //   zipcode: params['zipcode']
    // };
    // let data = null;
    // configs.data = data;

    configs.data = {
      address: params['address'],
      addressDetail: params['addressDetail'],
      answerExpected: params['answerExpected'],
      answerField: params['answerField'],
      answerPlan: params['answerPlan'],
      answerReason: params['answerReason'],
      answerRoute: params['answerRoute'],
      birthday: params['birthday'],
      contact: params['contact'],
      email: params['email'],
      isAgreeYn1: params['isAgreeYn1'],
      isAgreeYn2: params['isAgreeYn2'],
      name: params['name'],
      nameRep: params['nameRep'],
      partyAmount: params['partyAmount'],
      partyDate: params['partyDate'],
      partyName: params['partyName'],
      partyParticipant: params['partyParticipant'],
      partyPlace: params['partyPlace'],
      partySns: params['partySns'],
      partyTimetable: params['partyTimetable'],
      partyType: params['partyType'],
      zipcode: params['zipcode']
    }
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > party for UNICEF : 파티 이야기 목록
   */
  static partyStoryListUsingGet(options: IRequestOptions = {}): Promise<PartyVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/party/story';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OnceSupportControllerService {
  /**
   * 함께하는 방법 : 개인이 후원 > 일시후원 : 현재 진행중인 일시후원 목록 가져오기
   */
  static getOnceSupportDonorListUsingGet(options: IRequestOptions = {}): Promise<SupportVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/personal/once-support/donor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 : 개인이 후원 > 일시후원 : 일시후원에 관련된 뉴스/스토리 목록 가져오기 -삭제 필요(공통 사용 가능)
   */
  static getOnceSupportNewsListUsingGet(options: IRequestOptions = {}): Promise<BoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/personal/once-support/news';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 : 개인이 후원 > 일시후원 : 현재 진행중인 긴급구호 후원 목록 가져오기
   */
  static getOnceSupportReliefListUsingGet(options: IRequestOptions = {}): Promise<SupportVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/personal/once-support/relief';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RegularSupportControllerService {
  /**
   * 함께하는 방법 : 개인이 후원 > 정기후원 : 현재 후원가능한 정기후원 목록
   */
  static getRegularDonorListUsingGet(options: IRequestOptions = {}): Promise<SupportVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/personal/regular/donor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PopUpControllerService {
  /**
   * 배너 조회
   */
  static listUsingGet(
    params: {
      /** 1: 메인 / 2: 마이페이지 */
      kind?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/popup/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { kind: params['kind'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PublicationControllerService {
  /**
   * 하는일 > 자료실 > 우편 신청
   */
  static applyPublicationUsingPost(
    params: {
      /** 주소 */
      address: string;
      /** 상세주소 */
      addressDetail?: string;
      /** 신청자료 인덱스번호 */
      boardIndexNumber: string;
      /** 연락처(01011112222) */
      contact: string;
      /** 신청부수 */
      dataCnt: string;
      /** 이메일 */
      email: string;
      /** 개인정보 수집 동의여부(Y/N) */
      isAgreeYn1: string;
      /** 마케팅 동의 여부(Y/N) */
      isAgreeYn2?: string;
      /** 이름 */
      name: string;
      /** 소속 */
      position?: string;
      /** 신청목적 */
      purpose: string;
      /** 교육대상수 */
      studentCnt?: string;
      /** 교육횟수 */
      studyCnt?: string;
      /** 사용자아이디 */
      userId: string;
      /** 신청자 타입(학생/부모/선생님/기타(직접입력)) */
      userType: string;
      /** 바라는 자료 */
      wishList?: string;
      /** 우편번호 */
      zipCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/publication/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        addressDetail: params['addressDetail'],
        boardIndexNumber: params['boardIndexNumber'],
        contact: params['contact'],
        dataCnt: params['dataCnt'],
        email: params['email'],
        isAgreeYn1: params['isAgreeYn1'],
        isAgreeYn2: params['isAgreeYn2'],
        name: params['name'],
        position: params['position'],
        purpose: params['purpose'],
        studentCnt: params['studentCnt'],
        studyCnt: params['studyCnt'],
        userId: params['userId'],
        userType: params['userType'],
        wishList: params['wishList'],
        zipCode: params['zipCode']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 자료실 키워드 조회
   */
  static getCategoryListUsingGet(
    params: {
      /** 부모 코드번호(KW99) */
      parentCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PublicationVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/publication/keyword';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { parentCode: params['parentCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 자료실 목록 조회
   */
  static getNewsListUsingGet1(
    params: {
      /** 해시태그(카테고리) */
      category?: string[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PublicationVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/publication/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { category: params['category'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RightControllerService {
  /**
   * 권리교육 > World's Largest Lesson 신청하기
   */
  static insertWllApplyUsingPost(
    params: {
      /** 주소 */
      address: string;
      /** 상세주소 */
      addressDetail: string;
      /** 답변1 */
      answer1?: string;
      /** 답변10 */
      answer10?: string;
      /** 답변11 */
      answer11?: string;
      /** 답변12 */
      answer12?: string;
      /** 답변13 */
      answer13?: string;
      /** 답변14 */
      answer14?: string;
      /** 답변15 */
      answer15?: string;
      /** 답변16 */
      answer16?: string;
      /** 답변17 */
      answer17?: string;
      /** 답변18 */
      answer18?: string;
      /** 답변2 */
      answer2?: string;
      /** 답변3 */
      answer3?: string;
      /** 답변4 */
      answer4?: string;
      /** 답변5 */
      answer5?: string;
      /** 답변6 */
      answer6?: string;
      /** 답변7 */
      answer7?: string;
      /** 답변8 */
      answer8?: string;
      /** 답변9 */
      answer9?: string;
      /** 반 */
      classNum: string;
      /** 연락처 */
      contact: string;
      /** 교육 예정일 */
      educationDate: string;
      /** 이메일 */
      email: string;
      /** 학년 */
      grade: string;
      /** 개인정보 수집동의 필수 */
      isAgreeYn1: string;
      /** 개인정보 수집동의 선택 */
      isAgreeYn2: string;
      /** 신청인 */
      name: string;
      /** 신청경로 */
      route?: string;
      /** 학교명 */
      schoolName: string;
      /** 학생수 */
      studentCnt: string;
      /** 우편번호 */
      zipCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/right/WLL/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        addressDetail: params['addressDetail'],
        answer1: params['answer1'],
        answer10: params['answer10'],
        answer11: params['answer11'],
        answer12: params['answer12'],
        answer13: params['answer13'],
        answer14: params['answer14'],
        answer15: params['answer15'],
        answer16: params['answer16'],
        answer17: params['answer17'],
        answer18: params['answer18'],
        answer2: params['answer2'],
        answer3: params['answer3'],
        answer4: params['answer4'],
        answer5: params['answer5'],
        answer6: params['answer6'],
        answer7: params['answer7'],
        answer8: params['answer8'],
        answer9: params['answer9'],
        classNum: params['classNum'],
        contact: params['contact'],
        educationDate: params['educationDate'],
        email: params['email'],
        grade: params['grade'],
        isAgreeYn1: params['isAgreeYn1'],
        isAgreeYn2: params['isAgreeYn2'],
        name: params['name'],
        route: params['route'],
        schoolName: params['schoolName'],
        studentCnt: params['studentCnt'],
        zipCode: params['zipCode']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 아동권리 협약 > 관련 소개파일 조회
   */
  static getChildRightFileListUsingGet(
    params: {
      /** 주소 */
      address: string;
      /** 상세주소 */
      addressDetail: string;
      /** 답변1 */
      answer1?: string;
      /** 답변10 */
      answer10?: string;
      /** 답변11 */
      answer11?: string;
      /** 답변12 */
      answer12?: string;
      /** 답변13 */
      answer13?: string;
      /** 답변14 */
      answer14?: string;
      /** 답변15 */
      answer15?: string;
      /** 답변16 */
      answer16?: string;
      /** 답변17 */
      answer17?: string;
      /** 답변18 */
      answer18?: string;
      /** 답변2 */
      answer2?: string;
      /** 답변3 */
      answer3?: string;
      /** 답변4 */
      answer4?: string;
      /** 답변5 */
      answer5?: string;
      /** 답변6 */
      answer6?: string;
      /** 답변7 */
      answer7?: string;
      /** 답변8 */
      answer8?: string;
      /** 답변9 */
      answer9?: string;
      /** 반 */
      classNum: string;
      /** 연락처 */
      contact: string;
      /** 교육 예정일 */
      educationDate: string;
      /** 이메일 */
      email: string;
      /** 학년 */
      grade: string;
      /** 개인정보 수집동의 필수 */
      isAgreeYn1: string;
      /** 개인정보 수집동의 선택 */
      isAgreeYn2: string;
      /** 신청인 */
      name: string;
      /** 신청경로 */
      route?: string;
      /** 학교명 */
      schoolName: string;
      /** 학생수 */
      studentCnt: string;
      /** 우편번호 */
      zipCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RightVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/right/file';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        addressDetail: params['addressDetail'],
        answer1: params['answer1'],
        answer10: params['answer10'],
        answer11: params['answer11'],
        answer12: params['answer12'],
        answer13: params['answer13'],
        answer14: params['answer14'],
        answer15: params['answer15'],
        answer16: params['answer16'],
        answer17: params['answer17'],
        answer18: params['answer18'],
        answer2: params['answer2'],
        answer3: params['answer3'],
        answer4: params['answer4'],
        answer5: params['answer5'],
        answer6: params['answer6'],
        answer7: params['answer7'],
        answer8: params['answer8'],
        answer9: params['answer9'],
        classNum: params['classNum'],
        contact: params['contact'],
        educationDate: params['educationDate'],
        email: params['email'],
        grade: params['grade'],
        isAgreeYn1: params['isAgreeYn1'],
        isAgreeYn2: params['isAgreeYn2'],
        name: params['name'],
        route: params['route'],
        schoolName: params['schoolName'],
        studentCnt: params['studentCnt'],
        zipCode: params['zipCode']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FoSeoTagControllerService {
  /**
   * seo 태그조회
   */
  static selectBannerListUsingGet1(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/seoTag/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * seo meta 정보 조회
   */
  static selectDetailMetaUsingGet(
    params: {
    /** 개별 page url */
    targetPageUrl: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/seoTag/detail/{targetPageUrl}'

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { targetPageUrl: params['targetPageUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    })
  }
}
export class FoBannerControllerService {
  /**
   * 배너 조회
   */
  static selectBannerListUsingGet(
    params: {
      /** 배너 종류(0001: 후원자 참여 > 현장방문, 0002: 후원자 참여: 행사 이벤트, 0003: 후원자 참여: 참여 활동, 0004: 후원자 참여 후원자 이야기, 1001: 게시판 > 소식, 2001: 메인 > 캠페인 배너, 3001: 마이페이지 > 메인 배너, 4001: 함께하는 방법 > 기업기관 후원소식 */
      kind: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FoBannerVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/siteDesign/banner/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { kind: params['kind'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  static shortCutBannerGet(): Promise<FoBannerVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/my/personalization/banner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, '');
      let data = null;
      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VolunteerControllerService {
  /**
   * 함께하는 방법 > 자원봉사 : 모집공고 지원(공통 : 유챔프/캠페니어스)
   */
  static insertVolunteerApplyUsingPost(
    params: {
      /** [유챔프 필수]첨부파일번호 */
      applyAttGrpNo?: string;
      /** 생년월일 8자리(ex:19890101) */
      applyBirth: string;
      /** 성별(ex:남/여) */
      applyGender: string;
      /** 이메일주소 */
      applyUserEmail: string;
      /** 신청자명 */
      applyUserName: string;
      /** 휴대폰번호('-'제외) */
      applyUserPhone: string;
      /** [캠페이너스에서만 필수]학년 */
      grade?: string;
      /** 개인정보 이용동의 여부 */
      isAgreeYn: string;
      /** [캠페이너스에서만 필수]전공 */
      schoolMajor?: string;
      /** 학교명 */
      schoolName: string;
      /** [캠페이너스에서만 필수]학교타입(재학/휴학) */
      schoolType?: string;
      /** [캠페이너스에서만 필수]자기소개 */
      selfContents?: string;
      /** 구분(1: 유챔프/ 2:캠페이너스) */
      type: string;
      /** 모집공고 게시글 인덱스번호(ex:2) */
      volunteerIndexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/volunteer/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        applyAttGrpNo: params['applyAttGrpNo'],
        applyBirth: params['applyBirth'],
        applyGender: params['applyGender'],
        applyUserEmail: params['applyUserEmail'],
        applyUserName: params['applyUserName'],
        applyUserPhone: params['applyUserPhone'],
        grade: params['grade'],
        isAgreeYn: params['isAgreeYn'],
        schoolMajor: params['schoolMajor'],
        schoolName: params['schoolName'],
        schoolType: params['schoolType'],
        selfContents: params['selfContents'],
        type: params['type'],
        volunteerIndexNumber: params['volunteerIndexNumber']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > 자원봉사 : 모집공고 지원 수정
   */
  static modifyVolunteerApplyUsingPost(
    params: {
      /** [유챔프 필수]첨부파일번호 */
      applyAttGrpNo?: string;
      /** 생년월일 8자리(ex:19890101) */
      applyBirth: string;
      /** 성별(ex:남/여) */
      applyGender: string;
      /** 이메일주소 */
      applyUserEmail: string;
      /** 신청자명 */
      applyUserName: string;
      /** 휴대폰번호('-'제외) */
      applyUserPhone: string;
      /** [캠페이너스에서만 필수]학년 */
      grade?: string;
      /** 개인정보 이용동의 여부 */
      isAgreeYn: string;
      /** [캠페이너스에서만 필수]전공 */
      schoolMajor?: string;
      /** 학교명 */
      schoolName: string;
      /** [캠페이너스에서만 필수]학교타입(재학/휴학) */
      schoolType?: string;
      /** [캠페이너스에서만 필수]자기소개 */
      selfContents?: string;
      /** 구분(1: 유챔프/ 2:캠페이너스) */
      type: string;
      /** 모집공고 게시글 인덱스번호(ex:2) */
      volunteerIndexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/volunteer/modify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        applyAttGrpNo: params['applyAttGrpNo'],
        applyBirth: params['applyBirth'],
        applyGender: params['applyGender'],
        applyUserEmail: params['applyUserEmail'],
        applyUserName: params['applyUserName'],
        applyUserPhone: params['applyUserPhone'],
        grade: params['grade'],
        isAgreeYn: params['isAgreeYn'],
        schoolMajor: params['schoolMajor'],
        schoolName: params['schoolName'],
        schoolType: params['schoolType'],
        selfContents: params['selfContents'],
        type: params['type'],
        volunteerIndexNumber: params['volunteerIndexNumber']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > 자원봉사 : 모집공고 조회(공통 : 유챔프/캠페니어스)
   */
  static getVolunteerNofiticationUsingGet(
    params: {
      /** 구분(1: 유챔프/ 2:캠페이너스) */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VolunteerVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/volunteer/notification';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 함께하는 방법 > 자원봉사 : 모집공고 지원 상태 조회 api
   */
  static getVoluteerApplyStatusUsingGet(options: IRequestOptions = {}): Promise<VolunteerApplyStatusVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/volunteer/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SampleControllerService {
  /**
   * Sample 데이터목록 조회
   */
  static getSampleListUsingGet(options: IRequestOptions = {}): Promise<SampleVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/sample/qna';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 입력 값 리턴
   */
  static returnTextUsingGet(
    params: {
      /** 첫번째 입력숫자 */
      number1: string;
      /** 두번째 입력숫자 */
      number2: string;
      /** 입력문자 */
      text: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/sample/string';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { number1: params['number1'], number2: params['number2'], text: params['text'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FoAccessLogControllerService {
  /**
   * webAccessLog
   */
  static webAccessLogUsingGet(
    params: {
      /** 인덱스 번호 */
      urlStr: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/webAccessLog';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { urlStr: params['urlStr'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AnniversaryProjectControllerService {
  /**
   * 기념후원 프로젝트 > 기념후원 프로젝트 생성
   */
  static insertAnniversaryProjectUsingPost(
    params: ObjectVo = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/anniversary/insert';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      // get >> query string
      configs.params = null;

      // post >> payload
      let frmData = new FormData();
      Object.keys(params).forEach((key:string) => {
          if (key !== null && params[key] !== undefined){
            frmData.append(key, params[key]);
          }
      })
      configs.data = frmData; 

      // restful api
      axios(configs, resolve, reject);
    });
  }

  /**
   * 기념후원 프로젝트 > 기념후원 프로젝트 수정
   */
    static updateAnniversaryProjectUsingPost(
      params: ObjectVo = {} as any,
      options: IRequestOptions = {}
    ): Promise<any> {
      return new Promise((resolve, reject) => {
        let url = basePath + '/unicef/api/fo/anniversary/update';
  
        const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
        // get >> query string
        configs.params = null;
  
        // post >> payload
        let frmData = new FormData();
        Object.keys(params).forEach((key:string) => {
            if (key !== null && params[key] !== undefined){
              frmData.append(key, params[key]);
            }
        })
        configs.data = frmData; 
  
        // restful api
        axios(configs, resolve, reject);
      });
    }

  /**
   * 기념후원 프로젝트 > 후원 참여 메시지 블록 처리
   */
  static updateAnniversaryGiftMessageBlockUsingPost(
    params: ObjectVo = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/anniversary/gift/blockMsg';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      // get >> query string
      configs.params = null;

      // post >> payload
      let frmData = new FormData();
      Object.keys(params).forEach((key:string) => {
          if (key !== null && params[key] !== undefined){
            frmData.append(key, params[key]);
          }
      })
      configs.data = frmData; 

      // restful api
      axios(configs, resolve, reject);
    });
  }

  /**
   * 기념후원 프로젝트 > 기념후원 프로젝트 : 프로젝트 목록
   */
  static selectAnniversaryProjectListUsingGet(params: {
      type: string; // 목록 타입 (use, admin)
      keyword?: string; // 검색어
      displayState?:string; // 프로젝트 상태
    } = {} as any
    ,options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/anniversary/list';
      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        type: params['type'],
        searchWord: params['keyword'],
        searchDisplayState: params['displayState'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 기념후원 프로젝트 > 기념후원 프로젝트 : 프로젝트 상세
   */
  static selectAnniversaryProjectDetailGet(params: {
    indexNumber: number; // 일련번호
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/anniversary/detail/'+params['indexNumber'] ;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;
      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  
  /**
   * 기념후원 프로젝트 > 기념후원 프로젝트 : 내프로젝트 관리 상세
   */
  static selectAdminAnniversaryProjectDetailGet(params: {
    indexNumber: number; // 일련번호
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/anniversary/admin/'+params['indexNumber'] ;
      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      let data:anniversaryProjectVo = {};
      axios(configs, resolve, reject);
    });
  }
  /**
   * 기념후원 프로젝트 > 기념후원 프로젝트 : 기념기부 이야기
   */
  static selectAnniversaryStoryListUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/anniversary/storyList';
      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      let data:anniversaryStoryVo[] = [];
      axios(configs, resolve, reject);
    });
  }

    /**
   * 기념후원 프로젝트 > 기념후원 프로젝트 : 후원과 참여
   */
    static selectAnniversarySupportListUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
      return new Promise((resolve, reject) => {
        let url = basePath + '/unicef/api/fo/anniversary/supportList';
        const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
        let data:anniversarySupportVo[] = [];
        axios(configs, resolve, reject);
      });
    }
  
}


export interface ActiveVo {
  /**  */
  attGrpNo?: string;

  /**  */
  attGrpNoTn?: string;

  /**  */
  attUploadPath?: string;

  /**  */
  boardCategoryCode?: string;

  /**  */
  boardIndexNumber?: string;

  /**  */
  contents?: string;

  /**  */
  deleteYn?: string;

  /**  */
  dispEndDate?: string;

  /**  */
  dispStartDate?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  mobileYn?: string;

  /**  */
  newsMainYn?: string;

  /**  */
  optData1?: string;

  /**  */
  optData2?: string;

  /**  */
  optData3?: string;

  /**  */
  optData4?: string;

  /**  */
  optData5?: string;

  /**  */
  referUrl?: string;

  /**  */
  registerId?: string;

  /**  */
  relCategoryCode?: string;

  /**  */
  replyChkYn?: string;

  /**  */
  replyOkYn?: string;

  /**  */
  statusCode?: string;

  /**  */
  subject?: string;

  /**  */
  svrFileNm?: string;

  /**  */
  topYn?: string;

  /**  */
  viewCnt?: string;

  /**  */
  writerName?: string;
}

export interface AttFileVo {}

export interface BbsBoardVo {
  /**  */
  attachFileCnt?: string;

  /**  */
  bcTitle?: string;

  /**  */
  commentCnt?: string;

  /**  */
  contentEng?: string;

  /**  */
  contentKor?: string;

  /**  */
  endDate?: string;

  /**  */
  etc?: string;

  /**  */
  eventCode?: string;

  /**  */
  idx?: number;

  /**  */
  isHtml?: string;

  /**  */
  isTop?: string;

  /**  */
  memberID?: string;

  /**  */
  nextIndex?: string;

  /**  */
  nextRegdate?: string;

  /**  */
  nextTitle?: string;

  /**  */
  prevIndex?: string;

  /**  */
  prevRegdate?: string;

  /**  */
  prevTitle?: string;

  /**  */
  readCnt?: string;

  /**  */
  regDate?: string;

  /**  */
  reply?: string;

  /**  */
  replyDepth?: string;

  /**  */
  replyGroup?: string;

  /**  */
  replyOk?: string;

  /**  */
  replyOrder?: string;

  /**  */
  siteURL?: string;

  /**  */
  startDate?: string;

  /**  */
  status?: string;

  /**  */
  strProgres?: string;

  /**  */
  subject?: string;

  /**  */
  tab?: string;

  /**  */
  writerName?: string;

  /**  */
  youtubeUrl?: string;
}

export interface BoCalendarDrawingVo {
  /**  */
  addr1?: string;

  /**  */
  address?: string;

  /**  */
  age?: string;

  /**  */
  audit1?: string;

  /**  */
  audit2?: string;

  /**  */
  audit3?: string;

  /**  */
  birthday?: string;

  /**  */
  delYn?: string;

  /**  */
  description?: string;

  /**  */
  email?: string;

  /**  */
  fileGubun?: string;

  /**  */
  filePath?: string;

  /**  */
  fileSize?: string;

  /**  */
  gender?: string;

  /**  */
  indexNumber?: string;

  /**  */
  name?: string;

  /**  */
  originalFileName?: string;

  /**  */
  phone?: string;

  /**  */
  postCode?: string;

  /**  */
  prize?: string;

  /**  */
  realImageUrl?: string;

  /**  */
  registerDate?: string;

  /**  */
  registerIndex?: number;

  /**  */
  registerNumber?: string;

  /**  */
  rowNum?: string;

  /**  */
  showYn1?: string;

  /**  */
  showYn2?: string;

  /**  */
  storedFileName?: string;

  /**  */
  title?: string;
}

export interface BoardVo {
  /**  */
  arrAttFile?: AttFileVo[];

  /**  */
  arrRelBoard?: string[];

  /**  */
  attGrpNo?: string;

  /**  */
  attGrpNoMobile?: string;

  /**  */
  attGrpNoOpt1?: string;

  /**  */
  attGrpNoOpt2?: string;

  /**  */
  attGrpNoOpt3?: string;

  /**  */
  attGrpNoPc?: string;

  /**  */
  attGrpNoTn?: string;

  /**  */
  boardCategoryCode?: string;

  /**  */
  boardIndexNumber?: string;

  /**  */
  codeName?: string;

  /**  */
  contents?: string;

  /**  */
  deleteYn?: string;

  /**  */
  dispEndDate?: string;

  /**  */
  dispStartDate?: string;

  /**  */
  eventEndDate?: string;

  /**  */
  eventStartDate?: string;

  /**  */
  eventStatus?: string;

  /**  */
  eventStatusName?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  firstRegisterFullDate?: string;

  /**  */
  imgAttGrpNo?: string;

  /**  */
  imgAttGrpNoMobile?: string;

  /**  */
  imgAttGrpNoOpt1?: string;

  /**  */
  imgAttGrpNoOpt2?: string;

  /**  */
  imgAttGrpNoOpt3?: string;

  /**  */
  imgAttGrpNoPc?: string;

  /**  */
  imgAttGrpNoTn?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  mobileYn?: string;

  /**  */
  newsMainYn?: string;

  /**  */
  nextBoardIndexNumber?: string;

  /**  */
  nextRegDate?: string;

  /**  */
  nextSubject?: string;

  /**  */
  optData1?: string;

  /**  */
  optData2?: string;

  /**  */
  optData3?: string;

  /**  */
  optData4?: string;

  /**  */
  optData5?: string;

  /**  */
  prevBoardIndexNumber?: string;

  /**  */
  prevRegDate?: string;

  /**  */
  prevSubject?: string;

  /**  */
  referUrl?: string;

  /**  */
  registerId?: string;

  /**  */
  relBoardIndexNumber?: string;

  /**  */
  relCategoryCode?: string;

  /**  */
  replyChkYn?: string;

  /**  */
  replyOkYn?: string;

  /**  */
  rowNum?: number;

  /**  */
  statusCode?: string;

  /**  */
  subject?: string;

  /**  */
  topYn?: string;

  /**  */
  viewCnt?: string;

  /**  */
  writerName?: string;
}

export interface ChildApplyVo {
  /**  */
  agency?: string;

  /**  */
  applyAttGrpNo?: string;

  /**  */
  applyUserEmail?: string;

  /**  */
  applyUserId?: string;

  /**  */
  applyUserName?: string;

  /**  */
  applyUserPhone?: string;

  /**  */
  attGrpNo?: string;

  /**  */
  cancelDate?: string;

  /**  */
  contents?: string;

  /**  */
  dispFileNm?: string;

  /**  */
  indexNumber?: string;

  /**  */
  isAgreeYn1?: string;

  /**  */
  isAgreeYn2?: string;

  /**  */
  organType?: string;

  /**  */
  personnelCount?: string;

  /**  */
  selectDate?: string;

  /**  */
  selectTime?: string;

  /**  */
  status?: string;
}

export interface ChildScheduleDetailVo {
  /**  */
  status?: string;

  /**  */
  timeType?: string;
}

export interface ChildScheduleVo {
  /**  */
  scheduleDate?: string;

  /**  */
  timeStatus?: ChildScheduleDetailVo[];
}

export interface CommonResultVo {
  /**  */
  data?: object;

  /**  */
  resultCode?: string;

  /**  */
  resultMessage?: string;

  /**  */
  totalCount?: number;
}

export interface ContentVo {
  /**  */
  content?: string;

  /**  */
  firstRegisterDate?: string;
}

export interface CsrVo {
  /**  */
  bigCategoryCode?: string;

  /**  */
  categoryEtc?: string;

  /**  */
  csrCate1?: string;

  /**  */
  csrCate2?: string;

  /**  */
  csrDesc?: string;

  /**  */
  csrName?: string;

  /**  */
  csrSite?: string;

  /**  */
  csrTopImage?: string;

  /**  */
  csrTopLogo?: string;

  /**  */
  csrType?: string;

  /**  */
  csrYear?: string;

  /**  */
  displayYn?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  indexNumber?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  logoAttGrpNo?: string;

  /**  */
  orderNumber?: string;

  /**  */
  smallCategoryCode?: string;

  /**  */
  topImgAttGrpNo?: string;

  /**  */
  topLogoAttGrpNo?: string;
}

export interface EventVo {
  /**  */
  arrAttFile?: AttFileVo[];

  /**  */
  arrRelBoard?: string[];

  /**  */
  attGrpNo?: string;

  /**  */
  attGrpNoMobile?: string;

  /**  */
  attGrpNoOpt1?: string;

  /**  */
  attGrpNoOpt2?: string;

  /**  */
  attGrpNoOpt3?: string;

  /**  */
  attGrpNoPc?: string;

  /**  */
  attGrpNoTn?: string;

  /**  */
  boardCategoryCode?: string;

  /**  */
  boardIndexNumber?: string;

  /**  */
  codeName?: string;

  /**  */
  contents?: string;

  /**  */
  deleteYn?: string;

  /**  */
  dispEndDate?: string;

  /**  */
  dispStartDate?: string;

  /**  */
  eventEndDate?: string;

  /**  */
  eventStartDate?: string;

  /**  */
  eventStatus?: string;

  /**  */
  eventStatusName?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  firstRegisterFullDate?: string;

  /**  */
  imgAttGrpNo?: string;

  /**  */
  imgAttGrpNoMobile?: string;

  /**  */
  imgAttGrpNoOpt1?: string;

  /**  */
  imgAttGrpNoOpt2?: string;

  /**  */
  imgAttGrpNoOpt3?: string;

  /**  */
  imgAttGrpNoPc?: string;

  /**  */
  imgAttGrpNoTn?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  mobileYn?: string;

  /**  */
  newsMainYn?: string;

  /**  */
  nextBoardIndexNumber?: string;

  /**  */
  nextRegDate?: string;

  /**  */
  nextSubject?: string;

  /**  */
  optData1?: string;

  /**  */
  optData2?: string;

  /**  */
  optData3?: string;

  /**  */
  optData4?: string;

  /**  */
  optData5?: string;

  /**  */
  prevBoardIndexNumber?: string;

  /**  */
  prevRegDate?: string;

  /**  */
  prevSubject?: string;

  /**  */
  referUrl?: string;

  /**  */
  registerId?: string;

  /**  */
  relBoardIndexNumber?: string;

  /**  */
  relCategoryCode?: string;

  /**  */
  replyChkYn?: string;

  /**  */
  replyOkYn?: string;

  /**  */
  rowNum?: number;

  /**  */
  status?: string;

  /**  */
  statusCode?: string;

  /**  */
  subject?: string;

  /**  */
  topYn?: string;

  /**  */
  viewCnt?: string;

  /**  */
  writerName?: string;
}

export interface FaqCodeVo {
  /**  */
  code?: string;

  /**  */
  codeName?: string;

  /**  */
  deleteYn?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  indexNumber?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  parentCode?: string;

  /**  */
  parentCodeLevel?: string;

  /**  */
  title?: string;

  /**  */
  useYn?: string;
}

export interface FoBannerVo {
  /**  */
  attGrpNoMobile?: string;

  /**  */
  attGrpNoPc?: string;

  /**  */
  donateUrl?: string;

  /**  */
  endDate?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  imgAttGrpNoMobile?: string;

  /**  */
  imgAttGrpNoPc?: string;

  /**  */
  indexNumber?: string;

  /**  */
  kind?: string;

  /**  */
  registerName?: string;

  /**  */
  sort?: string;

  /**  */
  startDate?: string;

  /**  */
  subTitle?: string;

  /**  */
  targetBlankYn?: string;

  /**  */
  targetUrl?: string;

  /**  */
  title?: string;

  /** */
  moTitle?: string;

  /** */
  content?: string;
}

export interface GiftVo {
  /**  */
  bannerAttImgNo?: string;

  /**  */
  categoryCode?: string;

  /**  */
  categoryCodeName?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  imgPath?: string;

  /**  */
  imgProductAttImgNo1_140?: string;

  /**  */
  imgProductAttImgNo1_290?: string;

  /**  */
  imgProductAttImgNo1_420?: string;

  /**  */
  imgProductAttImgNo1_50?: string;

  /**  */
  imgProductAttImgNo2_140?: string;

  /**  */
  imgProductAttImgNo2_290?: string;

  /**  */
  imgProductAttImgNo2_420?: string;

  /**  */
  imgProductAttImgNo2_50?: string;

  /**  */
  imgProductAttImgNo3_140?: string;

  /**  */
  imgProductAttImgNo3_290?: string;

  /**  */
  imgProductAttImgNo3_420?: string;

  /**  */
  imgProductAttImgNo3_50?: string;

  /**  */
  imgProductAttImgNo4_140?: string;

  /**  */
  imgProductAttImgNo4_290?: string;

  /**  */
  imgProductAttImgNo4_420?: string;

  /**  */
  imgProductAttImgNo4_50?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  mainDisplayYn?: string;

  /**  */
  orderNumber?: number;

  /**  */
  productAttImgNo1?: string;

  /**  */
  productAttImgNo2?: string;

  /**  */
  productAttImgNo3?: string;

  /**  */
  productAttImgNo4?: string;

  /**  */
  productCode?: string;

  /**  */
  productExplanation?: string;

  /**  */
  productName?: string;

  /**  */
  productNameEn?: string;

  /**  */
  productPrice?: number;
}

export interface HospitalVo {
  /**  */
  firstLetter?: string;

  /**  */
  hospitalName?: string;

  /**  */
  letterGroup?: string;
}

export interface IGBannerVo {
  /**  */
  bannerTargetClick?: string;

  /**  */
  bannerTargetUrl?: string;

  /**  */
  indexNumber?: string;

  /**  */
  mobileBannerAttNoTn?: string;

  /**  */
  pcBannerAttNoTn?: string;

  /**  */
  recommItem01?: string;

  /**  */
  recommItem02?: string;

  /**  */
  recommItem03?: string;

  /**  */
  recommItem04?: string;

  /**  */
  recommItem05?: string;
}

export interface NewsVo {
  /**  */
  attGrpNo?: string;

  /**  */
  attGrpNoMobile?: string;

  /**  */
  attGrpNoOpt1?: string;

  /**  */
  attGrpNoOpt2?: string;

  /**  */
  attGrpNoOpt3?: string;

  /**  */
  attGrpNoPc?: string;

  /**  */
  attGrpNoTn?: string;

  /**  */
  boardCategoryCode?: string;

  /**  */
  boardIndexNumber?: string;

  /**  */
  contents?: string;

  /**  */
  deleteYn?: string;

  /**  */
  dispEndDate?: string;

  /**  */
  dispStartDate?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  imgAttGrpNo?: string;

  /**  */
  imgAttGrpNoMobile?: string;

  /**  */
  imgAttGrpNoOpt1?: string;

  /**  */
  imgAttGrpNoOpt2?: string;

  /**  */
  imgAttGrpNoOpt3?: string;

  /**  */
  imgAttGrpNoPc?: string;

  /**  */
  imgAttGrpNoTn?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  mobileYn?: string;

  /**  */
  newsMainYn?: string;

  /**  */
  optData1?: string;

  /**  */
  optData2?: string;

  /**  */
  optData3?: string;

  /**  */
  optData4?: string;

  /**  */
  optData5?: string;

  /**  */
  referUrl?: string;

  /**  */
  registerId?: string;

  /**  */
  relCategoryCode?: string;

  /**  */
  replyChkYn?: string;

  /**  */
  replyOkYn?: string;

  /**  */
  statusCode?: string;

  /**  */
  subject?: string;

  /**  */
  topYn?: string;

  /**  */
  viewCnt?: string;

  /**  */
  writerName?: string;
}

export interface OrgCategoryVo {
  /**  */
  board?: BoardVo[];

  /**  */
  orgIndexNumber?: number;

  /**  */
  orgName?: string;
}

export interface PartyVo {
  /**  */
  bcIdx?: string;

  /**  */
  content?: string;

  /**  */
  dt?: string;

  /**  */
  endDate?: string;

  /**  */
  partyTitle?: string;

  /**  */
  readCnt?: string;

  /**  */
  regDate?: string;

  /**  */
  siteUrl?: string;

  /**  */
  startDate?: string;

  /**  */
  subject?: string;
}

export interface PeopleActiveVo {
  /**  */
  activeCategoryName?: string;

  /**  */
  activeIndexNumber?: number;

  /**  */
  activeName?: string;

  /**  */
  attGrpNoActive?: string;

  /**  */
  attGrpNoDetail?: string;

  /**  */
  contentCategoryName?: string;

  /**  */
  relUrl?: string;

  /**  */
  videoUrl?: string;
}

export interface PeopleVo {
  /**  */
  active?: PeopleActiveVo[];

  /**  */
  activeExist?: number;

  /**  */
  attGrpNoDetail?: string;

  /**  */
  attGrpNoMain?: string;

  /**  */
  attGrpNoTn?: string;

  /**  */
  categoryCode?: string;

  /**  */
  categoryName?: string;

  /**  */
  detailContent?: string;

  /**  */
  detailPageYn?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  mainText?: string;

  /**  */
  name?: string;

  /**  */
  orderNumber?: number;

  /**  */
  peopleIndexNumber?: number;

  /**  */
  relUrl?: string;

  /**  */
  role?: string;

  /**  */
  subText?: string;
}

export interface PeriodVo {
  /**  */
  board?: BoardVo[];

  /**  */
  endPeriodDate?: string;

  /**  */
  indexNumber?: number;

  /**  */
  startPeriodDate?: string;
}

export interface PrivacyVo {
  /**  */
  companyName?: string;

  /**  */
  content?: string;

  /**  */
  content2?: string;

  /**  */
  departmentName?: string;

  /**  */
  endDate?: string;

  /**  */
  enforceDate?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  indexNumber?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  latestVersion?: string;

  /**  */
  setDateYn?: string;

  /**  */
  startDate?: string;

  /**  */
  useYn?: string;

  /**  */
  version?: string;
}

export interface PublicationVo {
  /**  */
  attGrpNo?: string;

  /**  */
  attGrpNoTn?: string;

  /**  */
  boardCategoryCode?: string;

  /**  */
  boardIndexNumber?: string;

  /**  */
  contents?: string;

  /**  */
  deleteYn?: string;

  /**  */
  dispEndDate?: string;

  /**  */
  dispStartDate?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  mobileYn?: string;

  /**  */
  newsMainYn?: string;

  /**  */
  optData1?: string;

  /**  */
  optData2?: string;

  /**  */
  optData3?: string;

  /**  */
  optData4?: string;

  /**  */
  optData5?: string;

  /**  */
  referUrl?: string;

  /**  */
  registerId?: string;

  /**  */
  relCategoryCode?: string;

  /**  */
  replyChkYn?: string;

  /**  */
  replyOkYn?: string;

  /**  */
  statusCode?: string;

  /**  */
  subject?: string;

  /**  */
  topYn?: string;

  /**  */
  viewCnt?: string;

  /**  */
  writerName?: string;
}

export interface RegularPayInfoVo {
  /** front-end 미사용 필드 */
  accountJuminNumber?: string;

  /** 계좌번호 ('-')제외 */
  accountNumber?: string;

  /** 예금주 */
  accountOwnerName?: string;

  /** 예금주연락처 */
  accountOwnerPhone?: string;

  /** 본인/타인(에금주) */
  accountType?: string;

  /** acsType(sign,ACS..) */
  acsType?: string;

  /** 은행번호 */
  bankCode?: string;

  /** 은행명 */
  bankName?: string;

  /** 생년월일(yyyyMMdd) */
  birthDay?: string;

  /** 사업자번호 */
  businessNumber?: string;

  /** 카드코드 */
  cardCode?: string;

  /** front-end 미사용 필드 */
  cardJuminNumber?: string;

  /** 카드사명 */
  cardName?: string;

  /** 카드번호 ('-')제외 */
  cardNumber?: string;

  /** donCode(D201..) */
  donCode?: string;

  /** 데이터 존재여부 */
  existYn?: string;

  /** 후원ID */
  giftId?: string;

  /** 결제정보 변경여부값(Y:변경가능) */
  isModifyOkYn?: string;

  /** 후원자타입코드 */
  memberTypeCode?: string;

  /** 결제일 */
  paymentDay?: string;

  /** FA04:계좌이체, FA05:카드, FANP:네이버페이 */
  paymentType?: string;

  /** 계좌이체,카드,네이버페이 */
  paymentTypeName?: string;

  /** CMS결제수단일 경우, 코드값 조회 ( FT03 : 계좌번호 오류 입니다. 변경 해 주세요) */
  regCd?: string;

  /** 예금주와의 관계 */
  relation?: string;

  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;

  /** 현재년월(yyMM) */
  thisMonth?: string;

  /** 유효기간(yyyyMM) */
  validDate?: string;

  /** 유효기간 월(MM) */
  validMonth?: string;

  /** 유효기간 연도(yy) */
  validYear?: string;

  /** 후원예정일(yyyy-mm-dd) */
  gifdate?: string;
}

export interface RightVo {}

export interface SampleVo {
  /**  */
  content?: string;

  /**  */
  id?: string;

  /**  */
  regdate?: string;

  /**  */
  title?: string;
}

export interface SupportVo {
  /**  */
  attGrpNoTn?: string;

  /**  */
  homepageTitle?: string;

  /**  */
  solicitCode?: string;

  /**  */
  supportCode?: string;

  /**  */
  supportSubTitle?: string;
}

export interface VolunteerApplyStatusVo {
  /**  */
  applyAttGrpNo?: string;

  /**  */
  applyBirth?: string;

  /**  */
  applyGender?: string;

  /**  */
  applyUserEmail?: string;

  /**  */
  applyUserName?: string;

  /**  */
  applyUserPhone?: string;

  /**  */
  attGrpNo?: string;

  /**  */
  dispFileNm?: string;

  /**  */
  isAgreeYn?: string;

  /**  */
  schoolMajor?: string;

  /**  */
  schoolName?: string;

  /**  */
  type?: string;

  /**  */
  uchampApplyCount?: number;
}

export interface VolunteerVo {
  /**  */
  attGrpNo?: string;

  /**  */
  contents?: string;

  /**  */
  dispEndDate?: string;

  /**  */
  dispStartDate?: string;

  /**  */
  expireYn?: string;

  /**  */
  filePath?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  isApplyCount?: number;

  /**  */
  lastModifyDate?: string;

  /**  */
  limitCount?: number;

  /**  */
  mailContents?: string;

  /**  */
  title?: string;

  /**  */
  totalCount?: number;

  /**  */
  type?: string;

  /**  */
  useYn?: string;

  /**  */
  volunteerIndexNumber?: string;
}

export interface YearVo {
  /**  */
  endPeriodDate?: string;

  /**  */
  indexNumber?: number;

  /**  */
  org?: OrgCategoryVo[];

  /**  */
  year?: string;
}

export interface myActiveHistoryVo {
  /** 계좌번호  */
  accountNumber?: string;

  /** 은행코드 */
  bankCode?: string;

  /** 은행명 */
  bankName?: string;

  /** 후원 금액 */
  bill?: number;

  /** 후원상품_상세설명 */
  cdDescriptionDetail?: string;

  /** 정기후원 총카운트  */
  count?: string;

  /** 후원상품설명 */
  description?: string;

  /** 후원코드 */
  donCode?: string;

  /** 후원코드명 */
  donName?: string;

  /** 후원자ID */
  donorId?: string;

  /** 후원신청일자 */
  giftDate?: string;

  /** 후원giftId */
  giftId?: string;

  /** 결제일  */
  payday?: string;

  /** 결제타입 */
  paymentType?: string;

  /** 결제타입명 */
  paymentTypeName?: string;

  /** pledge_payment_정기/일시후원 */
  pledgePayment?: string;

  /** P/G */
  recordType?: string;

  /** 후원상품코드 */
  solicitCode?: string;

  /** 최초후원일자  */
  startDonateDate?: string;

  /** 오늘날짜  */
  today?: string;
}

export interface myAlarmVo {
  /**  */
  alarmDate?: string;

  /**  */
  content?: string;

  /**  */
  giftId?: string;

  /**  */
  targetUrl?: string;
}

export interface myHistoryCodeVo {
  /**  카테고리 코드명 */
  categoryCodeName?: string;

  /**  상세설명 */
  description?: string;

  /** 후원코드(목록 검색을 위한 코드) */
  glCode?: string;

  /**  캠페인명(홈페이지 노출용) */
  homepageTitle?: string;

  /** 기간계후원명 */
  supportTitle?: string;
}

export interface myMissHistoryVo {
  /** 후원금액 */
  amount?: number;

  /** 후원상품_상세설명 */
  cdDescriptionDetail?: string;

  /** 후원상품설명 */
  description?: string;

  /** 후원코드명 */
  donName?: string;

  /** 잔액 */
  estimatedAmount?: number;

  /** 결제 예정일 */
  giftAidDate2?: string;

  /**  */
  giftId?: string;

  /** 후원 코드 */
  glCode?: string;

  /** 결제수단종류 */
  kind?: string;

  /** 월 조회 */
  month?: string;

  /** 후원 년/월 */
  paymonth?: string;

  /** pledge_payment_정기/일시후원 */
  pledgePayment?: string;

  /** P/G */
  recordType?: string;

  /** 상태 */
  statDesc?: string;

   /**결제 후 리턴 url*/
   rUrl?: string;
}

export interface myPageBoardContentsVo {
  /**  */
  attGrpNo?: string;

  /**  */
  indexNumber?: string;

  /**  */
  targetBlankYn?: string;

  /**  */
  targetUrl?: string;

  /**  */
  title?: string;

  /**  */
  isDonorYn?: string;

}

export interface myPageMainRegularVo {
  /** 첫번째 정기후원 금액 */
  bill?: number;

  /** 후원 상품명 */
  cdDescription?: string;

  /** 후원 상품 상세명 */
  cdDescriptionDetail?: string;

  /** 첫번째 정기후원id */
  giftId?: string;

  /** 후원코드 */
  glCode?: string;

  /** 첫번째 정기후원 결제일 */
  payDay?: string;

  /** 정기후원 총금액 */
  regularTotalAmount?: number;
}

export interface myPageQnaVo {
  /**  */
  replyOkCount?: string;

  /**  */
  replyWaitCount?: string;
}

export interface anniversaryProjectVo {
  /** 기념일 */
  anniDate? : string;
  /** 기념일 종류 */
  anniType? : string;
  /** 기념일 종류명 */
  anniTypeName?: string;
  /** 대표이미지 */
  aprjtAttImg? : string;
  /** 프로젝트 일련번호 */
  aprjtIdx? : number;
  /** 메시지 */
  aprjtMessage? : string;
  /** 프로젝트명 */
  aprjtName? : string;
  /** 게시글 공개 동의 여부 */
  confirmPublicYn? : string;
  /** 생성 결과 이메일 알림 여부 */
  confirmPushYn? : string;
  /** 노출상태유형코드(0 : 신규, 1 : 노출, 2 : 대기, 3 : 진행, 4: 종료) */
  displayTypeCode? : string;
  /** 목표금액 */
  goalAmount? : number;
  /** 목표기간 시작일 */
  goalStartDate? : string;
  /** 목표기간 종료일 */
  goalEndDate? : string;
  /** 캠페인코드 */
  solicitCode? : string;
  /** 후원코드(지원사업) */
  supportCode? : string;
  /** 후원코드 일련번호 */
  supportIndexNumber? : number;
  /** 이미지 URL */
  imgAttImgTn? : string;
  /** 상세페이지 URL */
  anniViewUrl? : string;
  /** SNS 공유 URL */
  anniSnsShareUrl? : string;
  /** 프로젝트 생성자 아이디 (작성자에게만 노출됨) */
  registerId? : string;
  /** 프로젝트 생성자 이름 */
  registerName? : string;
  /** 노출상태유형코드(0 : 대기, 1 : 수정, 2 : 보류, 3 : 진행, 4: 종료) */
  displayStateTypeCode? : string;
  /** 노출상태유형코드명 */
  displayStateTypeName? : string;
  

  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;
  
  /************************************* 이하 상세 페이지용 */
  /** 후원내역 */
  arrGift? : any;

  /** 후원내역 합계 정보 */
  giftTotalAmountInfo? : any;
  /** 후원내역 합계 정보 */
  giftTotalAmount? : number;
  /** 후원내역 건수 정보 */
  giftCountSupport? : number;

} 


export interface anniversaryStoryVo {
  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;


  /** 일련번호 */
  indexNumber? : number;
  /** 제목 */
  title? : string;
  /** 링크 URL */
  linkUrl? : string;
  /** 데스크탑용 이미지 */
  imgAttGrpNoPc? : string;
  /** 모바일용 이미지 이미지 */
  imgAttGrpNoMob? : string;

} 

export interface anniversarySupportVo {
  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;

  /** 일련번호 */
  indexNumber? : number;
  /** 제목 */
  title? : string;
  /** 요약 */
  summary? : string;
  /** 성명 */
  userName? : string;
  /** 데스크탑용 이미지 */
  imgAttGrpNoPc? : string;
  /** 모바일용 이미지 이미지 */
  imgAttGrpNoMob? : string;
} 

export interface metaDataVo {
  boardCategoryCode?: string | null;
  imageAltText?: string;
  imgOgImageAttGrpNo?: string;
  indexNumber?: number | null;
  metaAuthor?: string;
  metaDescription?: string;
  metaKeywords?: string;
  ogDescription?: string;
  ogImageAttGrpNo?: string;
  ogSiteName?: string;
  ogTitle?: string;
  ogimageUrl?: string | null;
  ogimageVar?: string | null;
  seoSubTypeCode?: string | null;
  seoTypeCode?: string | null;
  targetPageUrl?: string;
  title?: string;
}

export interface reliefPlaceVo {
  attGrpNoMobile?: string,
  attGrpNoPc?: string,
  imgAttGrpNoMobile?: string,
  imgAttGrpNoPc?: string,
  indexNumber?: string,
  keyword?: string,
  visualType?: string,
  visualUri?: string
}

/** ************ 1%의 기적 ************ */
export class OnePercentMiracleControllerService {
  /**
   * 1%의 기적 > 신청하기
   */
  static insertOnePercentMiracleApplyUsingPost(
    params: ObjectVo = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/onepercent-miracle/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      // get >> query string
      configs.params = null;

      // post >> payload
      let frmData = new FormData();
      Object.keys(params).forEach((key:string) => {
          if (key !== null && params[key] !== undefined){
            frmData.append(key, params[key]);
          }
      })
      configs.data = frmData; 

      // restful api
      axios(configs, resolve, reject);
    });
  }

  /**
   * 1%의 기적 > 후원방법 코드 목록
   */
  static selectOnePercentMiracleCodeListUsingGet(params: {
      codeGrp: string; // 코드그륩
    } = {} as any
    ,options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/onepercent-miracle/codes';
      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        codeGrp: params?.codeGrp,
      };
      configs.data = null;
      axios(configs, resolve, reject);
    });
  }
}
export interface onePercentMiracleCodeVo {
  /** 코드 */
  codeKey? : string;
  /** 코드명 */
  codeVal? : string;
  /** 노출순서 */
  orderNumber? : string;
}

export interface myMissModuleVo {
  /** 모듈 번호 */
  moduleCode?: String,

  /** 모듈 이름 */
  moduleName?: string,

  /** 메인메시지 */
  mainMessage?: string,

    /** 서브메시지 */
  subMessage?: string,

  /** 안내문구 */
  infoMessage?: string,

  /** 링크 안내문구 */
  supportUrlMessage?: string,

  /** 링크 타이틀 */
  supportUrlTitle?: string,

  /** pc이미지 */
  attGrpNoOpt1?: string,

  /** 모바일 이미지 */
  attGrpNoOpt2?: string

  /** 버튼 유무 */
  displayOption?: string,

    /** 후원URL */
    supportUrl?: string,


}

export interface certificationVo {
  /** 이름 */
  name?: string;
    /** 총 후원금액*/
  myTotalAmountStr?: number;
  /** 첫 후원일 */
  firstDonateDate?: string;
  /** 아이디 */
  userId?: string;
  /** 도너아이디 */
  donorId?: string;
  // 실패시
  resultMessage?: string;
}

export class MyMissHistoryModuleControllerService {
 

  /**
 * 소급모듈 > 설정 디자인 가져오기
 */
  static getMyMissModuleInfoGet(params: {
    cpCode: String; // urlKeyword
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/miss/missModuleInfo/'+params['cpCode'] ;
      //let url =  'http://localhost:8080/unicef/api/fo/common/miss/missModuleInfo/'+params['cpCode'] ;

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;
      configs.data = data;

      axios(configs, resolve, reject);
    });
}
}